
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.navbar-brand img{
  max-width: 250px;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
.v-text-field.v-text-field--solo .v-input__control input,
.v-text-field input,
.v-textarea textarea {
    opacity: 0.81;
}
